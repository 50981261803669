/* SIDEBAR */
#sidebar {
  min-width: 280px;
  max-width: 280px;
  position: fixed;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 40px;
  top: 76px;
  left: 0;
  overflow-y: scroll;
  color: #ffffff;
  transition: all 0.3s;
  height: 100vh;
}

.link-list-action-header {
  font-weight: 600;
  text-decoration: underline;
  text-align: left;
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: #adb5bd;
}

.sidebar-list a:link,
.sidebar-list a:hover,
.sidebar-list a:visited {
  color: #ffffff;
  text-decoration: none;
}

.sidebar-list a:hover {
  color: #212529;
  background: #ffffff;
  text-decoration: underline;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.sidebar-hidden {
  min-width: 0px;
  max-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-top: 5px;
}

.sidebar-ctrl-icon {
  height: 2em !important;
  width: 2em !important;
}

#sidebar svg {
  height: 1.1em;
  width: 1.1em;
}

#sidebar.active svg {
  height: 1.8em;
  width: 1.8em;
}

.sidebarLinkText {
  font-size: 1.1em;
  color: inherit;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  padding: 0px;
  height: 38px;

  text-align: right;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul.components {
  padding: 20px 0;
  /* border-bottom: 1px solid #47748b;  Might be able to use this for differentiating sections*/
}

#sidebar ul li a {
  padding: 5px 10px 5px 10px;
  font-size: 1.1em;
  display: block;
}

#searchInput {
  margin-bottom: 5px;
}

#sidebar span.arbadge {
  padding: 0.25em 0.4em;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  background-color: #6c757d;
}

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active > a,
#sidebar a[aria-expanded="true"] {
  color: #ffffff;
  background: #6c757d;
}

a[data-toggle="collapse"] {
  position: relative;
}

#sidebar.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#sidebar > ul > ul > a,
div.select-all-header {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6c757d !important;
}

#sidebar label.select-all-label {
  color: #ffffff !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Put some space at the bottom of the sidebar. Should be applied to last element of sidebar,
   but only when space is needed.
*/
.bottom-spacer {
  margin-bottom: 100px;
}

/* Bug in react-icons implementation of Grommet Icons. */
.grIcon path {
  stroke: currentColor;
}
