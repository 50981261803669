.fileUploadInput {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
}

.fileUploadInput label {
  display: flex;
  align-items: center;
  color: setColor(primary, 0.5);
  background: setColor(white);
  transition: 0.4s ease;
  font-family: arial, sans-serif;
  font-weight: regular;
}

.fileUploadInput input {
  position: relative;
  z-index: 1;
  padding: 0 gap(m);
  width: 100%;
  height: 60px;
  border: 1px solid #323262;
  border-radius: 3px;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: regular;
}
.fileUploadInput input[type="file"] {
  padding: 0 gap(m);
}
.fileUploadInput input[type="file"]::-webkit-file-upload-button {
  visibility: visible;
  margin: 10px;
  padding: 0;
  height: 40px;
  width: 100px;
  color: white;
  background-color: #056ddc;
  border-radius: .5rem;

}

.fileUploadInput button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  line-height: 0;
  user-select: none;
  color: white;
  background-color: #323262;
  border-radius: 0 3px 3px 0;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: 800;
}
.fileUploadInput button svg {
  width: auto;
  height: 50%;
}

.fileUploadInput body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(66, 50, 98, 0.05);
}

@-moz-document url-prefix() {
  .fileUploadInput button {
    display: none;
  }
}
