.breadcrumb {
  background: #6c757d;
  margin-bottom: 0px;
  line-height: 30px;
  padding: 0px 0px 0px 20px;
  border-radius: 0rem;
  margin-right: -30px;
  margin-left: 0px;
}

.breadcrumbSidebarClosed {
  margin-left: -30px;
}

.firstCrumb {
  padding-left: 30px;
}

.breadcrumb::before {
  padding-right: 5px;
  padding-left: 0px;
  color: black !important;
  content: ">";
}

.breadcrumb:first-child:before {
  padding-right: 0px;
  padding-left: 5px;
  color: black;
  content: "";
}

.breadcrumb.active {
  color: black;
}

.breadcrumb-item.active {
  color: black;
  font-weight: 600;
}

ol.breadcrumb {
  padding-inline-start: 0px;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: black !important;
  content: ">";
}

a.breadcrumb-item {
  color: #ffffff;
  text-decoration: none;
}

a.breadcrumb-item:hover {
  text-decoration: underline;
}
