.inline-cb {
  position: inherit;
  margin-left: 30px;
}

.noteActionOrange {
  color: #f5a91d !important;
}

.noteActionGreen {
  color: #40ba22 !important;
}

.react-bootstrap-table tbody tr {
  white-space: pre-line;
}

.pairing-btn-wrapper {
  display: inline-block;
}

#content
  > div
  > div
  > div.mt-3.overflow-auto.dg-no-expand-all.row
  > div
  > div
  > div.react-bootstrap-table
  > table
  > thead
  > tr
  > th.expand-cell-header {
  pointer-events: none;
}
