.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.claim-form-hr {
  flex: 1;
}

.lower-heading {
  padding-top: 2em;
}
