.footer {
  background-color: #dddddd;

  border-top: 1px solid #333333;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.footer a {
  text-decoration: none;
  color: #007bff;
}

.footer a:hover {
  text-decoration: underline;
}
