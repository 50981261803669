.header {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 76px;
  z-index: 10;
}

.bg-dark {
  background-color: #343a40 !important;
}

.nav-item a:link,
.nav-item a:visited {
  color: #ffffff;
  text-decoration: none;
}

.menu-sep {
  color: #dedde0;
}

#user-nav a:link,
#user-nav a:visited {
  color: #ffffff;
}

#user-nav a:hover,
.nav-item a:hover {
  color: #dedde0;
}

.local-app-nav,
.dev-app-nav {
  margin-left: 77px;
}

.qa-app-nav {
  margin-left: 140px;
}

.prod-app-nav {
  margin-left: 200px;
}
