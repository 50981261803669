.add-contract-form {
  max-width: 1200px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

div.first-check.form-check {
  padding-left: 0px !important;
}

div.form-check.includeProductCb {
  padding-left: 60px !important;
}

div.form-check {
  font-size: 1.1rem !important;
  padding-left: 1.25rem !important;
}

.manage-btn {
  margin-left: 60px;
}
