.data-grid-scroll-container.note-container {
  height: 950px;
  padding-right: 15px;
}

.noteDeleteBtn {
  position: absolute;
  top: -4px;
  right: 0px;
}
