.target-group-button {
  width: 100%;
  text-align: left;
}

.target-group-collection {
  font-weight: bold;
  margin-top: 2em;
  padding-left: 0.5em;
}

.target-categories-header {
  padding: 1em;
}

.title-row {
  font-weight: bold;
  padding-top: 1em;
}

.select-row {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.add-target-groups-form {
  max-width: 900px;
}

.check {
  text-align: right;
  padding-right: 0;
}

.scrollable-container {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1em;
}
