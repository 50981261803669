.quarterPageGrid {
  border-style: solid;
  border-width: 1px;
  border-color: #000000 !important;
  height: 175px;
  width: 100%;
  font-size: 70%;
  overflow: auto;
}

.quarterPageGrid td {
  padding: 0.1em;
  line-height: 0.25 !important;
  border: 0em;
  vertical-align: middle;
}

.quarterPageGrid .table-bordered th {
  border: 0px;
}

.btn-link {
  padding: 0.1rem;
}

.dashboardButton {
  text-align: left;
  font-size: 0.7rem;
}

.react-bootstrap-table tbody tr {
  white-space: nowrap;
}
