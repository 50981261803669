/* Color Classes */
.gray-900 {
  background-color: #212529;
}

.gray-800 {
  background-color: #343a40; /* Current Header Color */
}

.gray-700 {
  background-color: #495057; /* Current Sidebar Color */
}

.gray-600 {
  background-color: #6c757d;
}

.gray-500 {
  background-color: #adb5bd;
}

.gray-400 {
  background-color: #ced4da;
}

.gray-300 {
  background-color: #dee2e6;
}

.gray-200 {
  background-color: #e9ecef;
}

.gray-100 {
  background-color: #f8f9fa;
}

.wrapper {
  display: flex;
  width: 100%;
}

/* CONTENT */
#content {
  width: calc(100% - 250px);
  padding: 0px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

#content.active {
  width: calc(100% - 80px);
}

#content.sidebar-hidden {
  width: 100%;
}

.main-content {
  position: absolute;
  top: 76px !important;
  padding: 0px 0px 100px 0px;
  width: calc(100% - 30px);
  padding-left: 30px;
}

.sidebar-hidden {
  margin-left: 0px;
}

body {
  overflow-x: hidden;
}
