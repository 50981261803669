/* Pulling Old root vars for Bootstrap 4 to keep current colors in Bootstrap 5 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4c00ff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --disabled: #9a9fa4;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  line-height: 1.5 !important;
}
/* End Bootstrap 4 to Bootstrap 5 Fix */

.linkBtn {
  float: right;
}

.ndcLabel {
  font-weight: bold;
  color: #1c7d05;
}

.nonMatchNdcLabel {
  font-weight: lighter;
  color: #6c757d;
}

.chip-btn {
  color: #ff0000 !important;
  font-size: 1rem !important;
  vertical-align: auto !important;
  margin-right: 5px;
}

.inline-text-input-button {
  margin-top: 33px;
}

.listGroupBtn {
  padding: 0px;
}

.btn-link {
  color: #056ddc;
}

.tableBtn {
  margin-left: 5px;
}

.required-field {
  color: red;
}

.add-cursor {
  cursor: pointer;
}

.form-error {
  color: red;
  font-weight: bold;
}

.form-warning {
  color: goldenrod;
  font-weight: bold;
}

.approval-approved {
  color: var(--success);
}

.approval-pending {
  color: var(--warning);
}

.approval-rejected {
  color: var(--danger);
}

.info-color {
  color: var(--orange);
}

.approval-draft {
  color: lightgray;
}

.halfBold {
  font-weight: 600;
}

.fontSizeLarge {
  font-size: 1.2rem;
}

.fontSizeXLarge {
  font-size: 1.5rem;
}

.multi-check-header {
  padding-left: 24px !important;
}

.multi-check-container {
  width: 100%;
  height: 125px;
  margin-left: 0px;
}

.expand-row-label {
  font-size: 1.1rem;
  text-decoration: underline;
  font-weight: 500;
}

.expand-row-text {
  font-size: 1rem;
}

.form-check-label {
  margin-bottom: 0px;
}

/* Bootstrap 4 to Bootstrap 5 Fix */
a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}
/* End Bootstrap 4 to Bootstrap 5 Fix */

/* Styles for Actions column of data grids. */
a.actionIconLinkPrimary,
a:visited.actionIconLinkPrimary,
a:hover.actionIconLinkPrimary,
a:active.actionIconLinkPrimary {
  color: var(--primary);
}

button.actionIconBtnDanger,
button:visited.actionIconBtnDanger,
button:hover.actionIconBtnDanger,
button:active.actionIconBtnDanger {
  color: var(--danger);
}

button.actionIconBtnDisabled,
button:visited.actionIconBtnDisabled,
button:hover.actionIconBtnDisabled,
button:active.actionIconBtnDisabled {
  color: var(--disabled);
  cursor: default;
}

/* Global Styles for the data grid */
.react-bootstrap-table table {
  table-layout: auto !important;
  text-align: left;
}

.react-bootstrap-table th {
  margin: 10px 0 10px 5px;
  background-color: #dddddd;
  border-color: black;
}

.react-bootstrap-table td {
  border-left-color: black;
  border-right-color: black;
}

.borderedRow {
  border: #000000;
  border-style: solid;
  border-width: 1px;
}

.paddedBtn {
  padding: 6px;
}

.data-grid-scroll-container {
  width: 100%;
  height: 500px;
  overflow: auto;
  margin-left: 0px;
}

.data-grid-scroll-container-sidebar {
  width: 100%;
  height: 250px;
  overflow: auto;
  margin-left: 0px;
}

.react-bootstrap-table-page-btns-ul {
  float: left !important;
}

div.relative {
  position: relative;
  left: 1px;
}

.standard-form {
  max-width: 900px;
}

.inline-button {
  padding: 0px;
  margin-bottom: 3px;
}

.borderlessCell td {
  border-style: none;
}

/* Bootstrap 4 to Bootstrap 5 Fix */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

tr:last-child {
  border-bottom: 1px solid;
}

.table > thead {
  vertical-align: bottom;
  border-top: solid;
  border-top-width: 1px;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
/* End Bootstrap 4 to Bootstrap 5 Fix */

.borderlessCell .table-bordered th {
  border-style: none;
}

.horizontal-scrollable {
  overflow-x: auto !important;
}

.main-container {
  padding: 2em;
}

.clinicallySensitive-green {
  color: green;
}

.clinicallySensitive-red {
  color: #f4350d;
}

.react-bootstrap-table td > .selection-input-4 {
  margin-left: 3px !important;
}

.blue-txt {
  color: #056ddc;
}

.artooltip .tooltip-inner {
  background-color: lightgray;
  color: #000;
  border: 1px solid #062e56;
  font-weight: bold !important;
}

.lca-rebate-btn {
  margin-left: -166px !important;
  margin-top: -4px !important;
}

.target-rebate-btn {
  margin-left: 8px !important;
  margin-top: -4px !important;
}

.limit-v2-modal-height {
  height: 550px;
}

.nso-drug-name {
  font-weight: 800;
  color: #3e0faa;
}
