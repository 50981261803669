.warning-banner {
  margin: 2em;
  font-style: oblique;
}

.lock-btn {
  text-align: center;
}

.mpdExcludeGrid {
  border-style: solid;
  border-width: 1px;
  border-color: #000000 !important;
  height: 650px;
  overflow: auto;
}

.mpdModal {
  white-space: pre-wrap;
}

/* Center the select all checkbox in the table header */
.react-bootstrap-table th > .selection-input-4 {
  margin-left: 3px !important;
}

.slider-filter-sublabel {
  display: inline !important;
  padding: 0px !important;
  font-size: 14px !important;
}

.gpi-cost-details-container {
  border-style: solid;
  border-radius: 1px;
  border-color: gray;
}

.gpi-cost-details {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.gpi-cost-details-target {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.artas-label {
  color: var(--success);
}
